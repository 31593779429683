import NewHeader from "../components/NewHeader";
import DownloadCSS from "./Download.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import React,{useState,useEffect} from 'react';

function Download() {
  const imagestyle = {
      height: "180px",
      width: "180px",
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={DownloadCSS.content}>
      <NewHeader />
      <br/>
        <div className={DownloadCSS.rel1}>  
          <img src="/img/bg-app-download.png" width="768px"/>    
          <div className={DownloadCSS.dent01}>     
              <span><a href="/download/SmartoothPremium_DentService_20241004_28.apk" type="application/vnd.android.package-achive" download>치과병원서비스 APK Download (version 1.1.28)</a></span>
          </div>   
          <div className={DownloadCSS.dent02}>     
              <span><a href="/download/기기 및 SW 소개 ver.1.6_20230821.pdf" download>기기 및 SW 소개 ver1.6 Download</a></span>
          </div>    
          <div className={DownloadCSS.dent03}>     
              <span><a href="/download/어플리케이션 설치 조건 및 방법 ver.1.1_20230720.pdf" download>어플리케이션 설치방법 ver1.1 Download</a></span>
          </div>
          <div className={DownloadCSS.dent04}>         
              <span><a href="/download/디스플레이 기기 사용 매뉴얼 ver1.4_20230720.pdf" download>디스플레이 기기 사용 매뉴얼 ver1.4 Download</a></span>
          </div>
          <div className={DownloadCSS.dent05}>     
              <span><a href="/download/smartooth_school_service_20240711_1.2.8.apk" type="application/vnd.android.package-achive" download>유치원서비스 APK Download (version 1.2.8)</a></span>
          </div>   
          <div className={DownloadCSS.dent06}>     
              <span><a href="/download/smartoothdownload_100.apk" type="application/vnd.android.package-achive" download>스마투스다운로더 앱 다운로드</a>
              <br/><br/><a href="/download/smartooth_background.jpg" download>스마투스 로고 이미지 다운로드</a></span>
          </div>   
        </div>
      <br/>
      <Footer />
    </div>
  );
}
export default Download;
